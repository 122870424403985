import React, { useState, useRef, useContext } from 'react';
import Head from 'next/head';
import Router from 'next/router';
import {
	Container, Row, Col, Form, Button, Input, InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';
import { useFormik } from 'formik';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
	faUser, faLock, faSpinner, faCheck, faInfoCircle, faCopyright,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'utils/axios';
import { LOGIN_STATUS, USER_GROUP } from 'utils/constant';
import { ContextStore } from 'contexts';
import * as Sentry from '@sentry/nextjs';

const Login = () => {
	const [error, setError] = useState('');
	const [loginStatus, setLoginStatus] = useState(LOGIN_STATUS.DEFAULT);
	const inputRef = useRef();
	const { uDispatch } = useContext(ContextStore);

	const formik = useFormik({
		initialValues: { user: '', pass: '' },
		onSubmit: async (data, { resetForm }) => {
			if (!data.user || !data.pass) {
				setError('請輸入帳號和密碼');
				return;
			}
			setLoginStatus(LOGIN_STATUS.LOADING);
			try {
				const { data: res } = await axios.post('/api/v1/user/login', {
					user: data.user,
					pass: data.pass,
				});
				setLoginStatus(LOGIN_STATUS.SUCCESS);
				Sentry.configureScope((scope) => {
					scope.setUser({
						id: data._id,
						username: data.user,
						name: data.name,
						group: data.group,
					});
				});
				uDispatch({
					type: 'LOGIN',
					payload: res,
				});
				switch (res.group) {
				case USER_GROUP.ADMIN:
					Router.push('/admin');
					break;
				case USER_GROUP.DEPT:
					Router.push('/dept');
					break;
				case USER_GROUP.TEACHER:
				default:
					Router.push('/');
					break;
				}
			} catch (e) {
				const headers = e?.response?.headers;

				switch (e?.response?.status) {
				case 401:

					if (headers['x-failed-login-count']) {
						setError(`已輸入錯誤 ${headers['x-failed-login-count']} 次，連續錯誤 5 次將被鎖定 15 分鐘`);
					} else {
						setError('帳號或密碼錯誤，或您無擔任委員而無法登入。');
					}
					break;
				case 403:
					setError('無法登入，可能目前並無擔任審查委員，請聯絡系務祕書');
					break;
				case 429:
					setError('登入次數過多，請 15 分鐘後再試');
					break;
				default:
					setError('未知的錯誤');
				}
				setLoginStatus(LOGIN_STATUS.DEFAULT);
				inputRef.current.focus();
				resetForm();
			}
		},
	});

	return (
		<div>
			<Head>
				<title>登入 | 高大評分登錄系統</title>
			</Head>
			<div className="help" />
			<div className="about text-muted" />
			<Container>
				<Row>
					<Col lg={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
						<div className="bg-white text-center text-dark rounded shadow-lg mt-5 p-3 pt-4 pb-5">
							{error && <div className="text-danger">{error}</div>}
							<h1>高大評分登錄系統</h1>
							<Form onSubmit={formik.handleSubmit}>
								<InputGroup className="mt-4 px-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<Fa icon={faUser} />
										</InputGroupText>
									</InputGroupAddon>
									<Input placeholder="帳號（教務系統帳號）" name="user" {...formik.getFieldProps('user')} autoFocus innerRef={inputRef} />
								</InputGroup>
								<InputGroup className="mt-4 px-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<Fa icon={faLock} />
										</InputGroupText>
									</InputGroupAddon>
									<Input type="password" placeholder="密碼（教務系統密碼）" name="pass" {...formik.getFieldProps('pass')} />
								</InputGroup>
								<Button className="mt-4" color="primary" type="submit">
									{
										(() => {
											switch (loginStatus) {
											case LOGIN_STATUS.LOADING:
												return <Fa className="fa-spin" icon={faSpinner} />;
											case LOGIN_STATUS.SUCCESS:
												return <Fa icon={faCheck} />;
											case LOGIN_STATUS.DEFAULT:
											default:
												return '登入';
											}
										})()
									}
								</Button>
							</Form>
						</div>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col className="text-center">
						<a href="/操作手冊.pdf">
							<Fa icon={faInfoCircle} />
							{' '}
							操作手冊
						</a>
						<div className="text-muted mt-1">
							<small>
								<Fa icon={faCopyright} />
								{' '}
								伯亨資訊服務有限公司 · 高雄大學招生專業化發展計畫辦公室
							</small>
						</div>
					</Col>
				</Row>
			</Container>
			<style global jsx>
				{`
					body {
						background: #f9f9fb;
					}
					.about {
						position: absolute;
						bottom: .5rem;
						left: .5rem;
					}
					.help {
						position: absolute;
						top: .5rem;
						right: .5rem;
					}
				`}
			</style>
		</div>
	);
};

export default Login;
